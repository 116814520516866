import { badgesPlugin } from '@backstage-community/plugin-badges';
import { configCatPlugin } from '@internal/plugin-config-cat';
import { signalsPlugin } from '@backstage/plugin-signals';
import { frontendCommonPlugin } from '@internal/backstage-plugin-frontend-common-react';
import { automatonPlugin } from '@internal/backstage-plugin-automaton';

export const plugins = [
  badgesPlugin,
  configCatPlugin,
  signalsPlugin,
  frontendCommonPlugin,
  automatonPlugin,
];
