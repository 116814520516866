import { FormFieldOptions } from '@internal/backstage-plugin-automaton';
import {
  automatonPlugin,
  createAutomatonFormFieldExtension,
} from '@internal/backstage-plugin-automaton';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useCallback, useEffect, useState } from 'react';
import { UserEntity } from '@backstage/catalog-model';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { stringifyEntityRef } from '@backstage/catalog-model';

const CatalogUserField = ({ onValueChange }: FormFieldOptions) => {
  const catalogApi = useApi(catalogApiRef);

  const [users, setUsers] = useState<UserEntity[]>([]);

  const getUsers = useCallback(async () => {
    const { items } = await catalogApi.getEntities({
      filter: [
        {
          kind: 'User',
        },
      ],
    });

    const users = items.filter(item => !!item?.metadata?.annotations?.['microsoft.com/email']);

    setUsers(users as UserEntity[]);
  }, [catalogApi]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onChange = (_event, value) => {
    if (!value) {
      onValueChange(value);
      return;
    }
    const entityRef = stringifyEntityRef(value as UserEntity);

    onValueChange(entityRef);
  };

  return (
    <Autocomplete
      options={users}
      getOptionLabel={option =>
        `${option.spec.profile?.displayName} (${option.spec.profile?.email})`
      }
      onChange={onChange}
      renderInput={params => <TextField {...params} label="Select a user" />}
    />
  );
};

export const CatalogUserFieldExtension = automatonPlugin.provide(
  createAutomatonFormFieldExtension({
    name: 'catalog-user',
    component: CatalogUserField,
  }),
);
