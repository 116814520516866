import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef, statusRouteRef, workflowCreateRouteRef } from './routes';

export const automatonPlugin = createPlugin({
  id: 'automaton',
  routes: {
    root: rootRouteRef,
    create: workflowCreateRouteRef,
    status: statusRouteRef,
  },
});

export const AutomatonPage = automatonPlugin.provide(
  createRoutableExtension({
    name: 'AutomatonPage',
    component: () => import('./pages/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
