import { ComponentType, PropsWithChildren } from 'react';
import { Extension, attachComponentData } from '@backstage/core-plugin-api';
import { FIELD_EXTENSION_KEY, FIELD_EXTENSION_WRAPPER_KEY } from './keys';
import { FormFieldExtensionComponent, FormFieldExtensionOptions } from '../types/extensions';

export const createAutomatonFormFieldExtension = (
  options: FormFieldExtensionOptions,
): Extension<FormFieldExtensionComponent> => {
  const expose: Extension<FormFieldExtensionComponent>['expose'] = () => {
    const fieldExtensionDataHolder: any = () => null;

    attachComponentData(fieldExtensionDataHolder, FIELD_EXTENSION_KEY, options);

    return fieldExtensionDataHolder;
  };

  return {
    expose,
  };
};

export const AutomatonFormFieldExtensions: ComponentType<
  PropsWithChildren<{}>
> = (): JSX.Element | null => null;

attachComponentData(AutomatonFormFieldExtensions, FIELD_EXTENSION_WRAPPER_KEY, true);
