import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'automaton',
});

export const statusRouteRef = createSubRouteRef({
  id: 'automaton-workflow-status',
  parent: rootRouteRef,
  path: '/status/:id',
});

export const workflowCreateRouteRef = createSubRouteRef({
  id: 'automaton-workflow-create',
  parent: rootRouteRef,
  path: '/workflows/:name',
});
